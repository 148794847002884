import React from 'react'
import PreLoadingGif from 'assets/pre-loading.webm'
import Diamond from 'assets/logo192.png'
import './PrelLoading.css'

export const PreLoading = () => {
	return (
		<div id='pre-loading' className='pre-loading'>
			{window?.innerWidth > 560 ? (
				<video loop muted autoPlay playsInline type='video/webm' src={PreLoadingGif} />
			) : (
				<img src={Diamond} alt='' />
			)}
		</div>
	)
}
