/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import { useLocation } from 'react-router-dom'

export const onMoveAnimation = (ele, animation) => {
	const root = document.querySelector(`#${ele}`)
	if (root) {
		root.style.animation = `${animation} .3s`
		root.style.animationFillMode = 'forwards'
	}
}
export const useReRenderComponent = (state, anchor) => {
	const [isRender, setIsRender] = React.useState(false)
	React.useEffect(() => {
		if (state?.destination) {
			if (state?.destination.anchor === anchor) {
				setIsRender(true)
			} else {
				setIsRender(false)
			}
		}
	}, [state?.destination?.anchor])
	return isRender
}
export const onTriggerMenu = () => {
	const root = document.querySelector('#menu')
	if (root) {
		if (root.style.animationName !== 'moveInCircle') {
			root.style.animation = 'moveInCircle .5s'
			root.style.animationFillMode = 'forwards'
		} else {
			root.style.animation = 'moveOutCircle .5s'
			root.style.animationFillMode = 'forwards'
		}
	}
}
export const onScrollTop = () => {
	window.scrollTo({
		top: 0,
		behavior: 'smooth',
	})
}

export const useRouteTopPage = () => {
	const location = useLocation()
	React.useEffect(() => {
		onScrollTop()
	}, [location.pathname])
}
const toggleVisibility = () => {
	const btn = document.querySelector('#btn-scroll')
	if (btn) {
		if (window.pageYOffset > 300) {
			btn.style.transform = 'translateY(0)'
			onMoveAnimation('header-text', 'moveOutOpacity')
		} else {
			btn.style.transform = 'translateY(200px)'
		}
	}
}
export const useShowButtonScroll = () => {
	React.useEffect(() => {
		window.addEventListener('scroll', toggleVisibility)
	}, [])
}
